body > #root > div {
  height: 100vh;
  font-family: sans-serif;
  font-size: large;
}

body {
  margin: 0;
  background-color: #fbfbfb;
}

img {
  max-width: 100%;
  object-fit: "contain";
}
